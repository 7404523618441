import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const GridSC = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 33% 33% auto;
  box-sizing: border-box;
`;

const InvisBtnSC = styled.button`
  border: none;
  cursor: pointer;

  width: 100%;
  height: 33vw;
  padding: 0;
`;

const GalleryGrid = ({ images, handleOpen }) => {
  return (
    <GridSC>
      {images.map((image, i) => (
        <InvisBtnSC key={i} onClick={handleOpen(i)}>
          {image}
        </InvisBtnSC>
      ))}
    </GridSC>
  );
};

GalleryGrid.propTypes = {
  images: PropTypes.array,
};
export default GalleryGrid;
