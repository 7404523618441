import React, { useState } from 'react';
import GalleryGrid from './GalleryGrid';
import LightBox from './Lightbox';

interface ImageProps {
  imagesGrid: JSX.Element[];
  imagesLightBox: JSX.Element[];
}

const Gallery = ({ imagesGrid, imagesLightBox }: ImageProps) => {
  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpen = (i) => (e) => {
    setShowLightbox(true);
    setSelectedImage(i);
  };
  const handleClose = () => {
    setShowLightbox(false);
    setSelectedImage(null);
  };
  const handlePrevRequest = (i, length) => (e) => {
    setSelectedImage((i - 1 + length) % length);
  };
  const handleNextRequest = (i, length) => (e) => {
    setSelectedImage((i + 1) % length);
  };

  return (
    <>
      <GalleryGrid images={imagesGrid} handleOpen={handleOpen} />
      {showLightbox && selectedImage !== null && (
        <LightBox
          images={imagesLightBox}
          handleClose={handleClose}
          handleNextRequest={handleNextRequest}
          handlePrevRequest={handlePrevRequest}
          selectedImage={selectedImage}
        />
      )}
    </>
  );
};
export default Gallery;
