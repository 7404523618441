import React from 'react';
import { Helmet } from 'react-helmet';
import { seed } from '../content/seed';
import { theme } from '../design/theme';

const MetaTags = () => {
  return (
    <Helmet
      htmlAttributes={{
        lang: 'sk',
      }}
      title={seed.meta.title}
      defer={false}
    >
      <meta charSet="utf-8" />
      <title>{seed.meta.title}</title>
      <link rel="canonical" href="https://unikovo.sk/" />
      <meta name="description" content={seed.meta.description} />
      <meta name="theme-color" content={theme.color.blue} />
      <meta name="msapplication-navbutton-color" content={theme.color.blue} />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content={theme.color.blue}
      />
      <meta
        name="google-site-verification"
        content="KWapyFxmD9xwX6fY6v2HUrZVXsYggwXCt_wsQEuGhTo"
      />

      <meta name="keywords" content={seed.meta.keywords} />
      <meta property="og:title" content={seed.meta.title} />
      <meta property="og:description" content={seed.meta.description} />
      <meta property="og:locale" content="sk_SK" />
      <meta property="og:site_name" content={seed.title.title} />
      <meta name="robots" content="index, follow" />
      <meta name="googlebot" content="index,follow,snippet,archive" />
      <meta name="author" content={seed.footer.author} />
    </Helmet>
  );
};

export default MetaTags;
