import React from 'react';
import { PageProps } from 'gatsby';
import MetaTags from '../components/MetaTags';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import Gallery from '../components/Gallery';
import SiteTemplate from '../components/pagesTemplate/SiteTemplate';
import { Back } from '../components/Back';

interface ImageFile {
  file: ImageDataLike;
}

interface Context {
  title: string;
  typ: string;
  id: number;
  gallery: ImageFile[];
}

const GalleryPage = (props: PageProps) => {
  const context: Context = props.pageContext as Context;

  const images =
    context.gallery?.map((img, index) => (
      <GatsbyImage
        image={getImage(img.file)}
        alt={`${context.title}-${index}`}
        style={{ width: 'auto', height: '100%' }}
        objectFit="cover"
        loading="lazy"
      />
    )) || [];

  const lightBoxImages =
    context.gallery?.map((img, index) => (
      <GatsbyImage
        image={getImage(img.file)}
        alt={`${context.title}-${index}`}
        style={{ width: '100%', height: '100%' }}
        objectFit="scale-down"
        loading="eager"
      />
    )) || [];

  return (
    <SiteTemplate>
      <MetaTags />
      <Back to="/#home" text={context.title} />
      <Gallery imagesGrid={images} imagesLightBox={lightBoxImages} />
    </SiteTemplate>
  );
};

export default GalleryPage;
